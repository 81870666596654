import AUTH from '@/constants/auth.constant';
import { COMMON, MODULE } from '@/constants/store.constant';
import { logIn, logOut } from '@/services/api/auth.service';
import { getUserByUsername } from '@/services/api/user.service';

const initialState = () => ({
  isLoggedIn: false,
  username: '',
  tenants: [],
  currentTenant: null,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async [AUTH.LOG_IN] ({ dispatch }, auth) {
      await logIn(auth);
      localStorage.setItem(AUTH.USER_KEY, auth.username);
      const { data: user } = await getUserByUsername(auth.username);
      localStorage.setItem('tenants', JSON.stringify(user.tenants));
      dispatch(AUTH.INIT_SESSION);
    },
    async [AUTH.LOG_OUT] ({ dispatch }) {
      logOut();
      localStorage.removeItem(AUTH.USER_KEY);
      localStorage.removeItem('tenants');
      await dispatch(AUTH.UPDATE_SESSION, { username: '', loginState: false });
      location.reload();
    },
    [AUTH.INIT_SESSION] ({ dispatch, commit }) {
      const username = localStorage.getItem(AUTH.USER_KEY);
      const tenants = JSON.parse(localStorage.getItem('tenants'));
      dispatch(AUTH.UPDATE_SESSION, { username, loginState: !!username });
      if (tenants) {
        dispatch(`${MODULE.PERMISSIONS}/updatePermissions`, tenants, { root: true });
        dispatch(AUTH.UPDATE_TENANTS, tenants);
        if (tenants.length > 0) {
          const defaultTenant = tenants.find((tenant) => tenant.code !== 'EXCHANGE_MONITORS' && tenant.code !== 'ADMIN');
          if (defaultTenant) {
            commit(COMMON.UPDATE_STATE, { name: 'currentTenant', value: defaultTenant.code });
          }
        }
      }
    },
    [AUTH.UPDATE_SESSION] ({ commit }, { username, loginState }) {
      commit(COMMON.UPDATE_STATE, { name: 'username', value: username });
      commit(COMMON.UPDATE_STATE, { name: 'isLoggedIn', value: loginState });
    },
    [AUTH.UPDATE_TENANTS] ({ commit }, tenants) {
      commit(COMMON.UPDATE_STATE, { name: 'tenants', value: tenants });
    },
    [AUTH.SET_CURRENT_TENANT] ({ commit }, tenant) {
      commit(COMMON.UPDATE_STATE, { name: 'currentTenant', value: tenant });
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return (name) => state[name];
    },
  },
};
